import { useLocation, useNavigation, useSearchParams } from 'react-router';
import type { SortDirection } from '@venncity/block';
import type { SortDescriptor } from '@venncity/block/molecules/table/table';
import {
  camelCase,
  flow,
  isArray,
  isBoolean,
  isEqual,
  isNull,
  kebabCase,
  mapKeys,
  reduce,
} from 'lodash-es';
import React from 'react';
import type { CamelCase, CamelCasedProperties, ValueOf } from 'type-fest';

export const ListSearchParams = {
  Page: 'page',
  Limit: 'limit',
  Offset: 'offset',
  OrderBy: 'order-by',
  Query: 'q',
};

export const GlobalSearchParam = {
  ...ListSearchParams,
  DialogType: 'dialog-type',
  NotificationId: 'notification-id',
  EventId: 'event-id',
  FeaturedSlideId: 'featured-slide-id',
  GeneralInfoPageId: 'general-info-page-id',
  ResidentId: 'resident-id',
  CommunityId: 'community-id',
  Timezone: 'timezone',
  CommunityTimezone: 'current-hood-timezone',
  UserTimezone: 'user-timezone',
} as const;

export const GlobalDialogType = {
  CreateNotification: 'create-notification',
  EditNotification: 'edit-notification',
  EventForm: 'event-form',
  GroupForm: 'group-form',
  DiscussionForm: 'discussion-form',
  FeaturedSlideForm: 'featured-slide-form',
  ChangeBackgroundImage: 'change-background',
  Settings: 'settings',
  GeneralInfoPageForm: 'general-info-page-form',
  LeaseContractForm: 'lease-contract-form',
  ShowsIntroductoryVideo: 'shows-introductory-video',
  CreateMoveInNotification: 'create-move-in-notification',
  ResidentProfile: 'resident-profile',
  CreateResidentNotification: 'create-resident-notification',
} as const;

export type GlobalDialogTypeUnion = (typeof GlobalDialogType)[keyof typeof GlobalDialogType];

export type SearchNavigateValue = string | string[] | number | boolean | undefined;

export type SearchParamsHash = {
  // accept any of the defined global search params
  [K in keyof CamelCasedProperties<
    Omit<typeof GlobalSearchParam, 'DialogType'>
  >]?: SearchNavigateValue;
  // Accept only allowed properties for the dialog type
} & Partial<
  Record<CamelCase<(typeof GlobalSearchParam)['DialogType']>, ValueOf<typeof GlobalDialogType>>
> &
  // accept any string
  Record<string, SearchNavigateValue>;

export function searchParamsHash(hash: SearchParamsHash, base?: URLSearchParams) {
  const searchParams = new URLSearchParams(base);

  for (const [key, value] of Object.entries(hash)) {
    const normalizedKey = kebabCase(key);

    if (value === undefined) {
      searchParams.delete(normalizedKey);
    } else if (isBoolean(value)) {
      searchParams.set(normalizedKey, String(value ? 1 : 0));
    } else if (isNull(value)) {
      searchParams.set(normalizedKey, '');
    } else if (isArray(value)) {
      if (searchParams.has(normalizedKey)) {
        searchParams.delete(normalizedKey);
      }

      for (const item of value) {
        searchParams.append(normalizedKey, String(item));
      }
    } else {
      searchParams.set(normalizedKey, String(value));
    }
  }

  return searchParams;
}

export function useSearchNavigate() {
  const [, setSearchParams] = useSearchParams();

  return React.useCallback(
    (params: SearchParamsHash, options?: { preventScrollReset?: boolean }) => {
      setSearchParams((searchParams) => searchParamsHash(params, searchParams), {
        ...options,
        replace: true,
      });
    },
    [setSearchParams],
  );
}

export function useSearchNavigation() {
  const location = useLocation();
  const navigation = useNavigation();

  if (navigation.location?.search) {
    const currentSearchParams = new URLSearchParams(location.search);
    const currentSearchParamsHash = Object.fromEntries(currentSearchParams.entries());

    const updatingSearchParams = new URLSearchParams(navigation.location.search);
    const updatingSearchParamsHash = Object.fromEntries(updatingSearchParams.entries());

    return reduce(
      currentSearchParamsHash,
      function (result, value, key) {
        return isEqual(value, updatingSearchParamsHash[key]) ? result : result.concat(key);
      },
      [] as string[],
    );
  }

  return [];
}

export function serialize(payload: Record<string, unknown>) {
  return flow(
    (obj) => mapKeys(obj, (_, key) => camelCase(key)),
    JSON.stringify,
    btoa,
    encodeURIComponent,
  )(payload);
}

export function deserialize<T extends object>(serialized: string | null) {
  if (!serialized) return null;

  return flow(decodeURIComponent, atob, JSON.parse)(serialized) as T;
}

export function useUrl() {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();
  const location = useLocation();

  const globalSearchParams = {
    page: searchParams.get(GlobalSearchParam.Page),
    limit: searchParams.get(GlobalSearchParam.Limit),
    offset: searchParams.get(GlobalSearchParam.Offset),
    orderBy: searchParams.get(GlobalSearchParam.OrderBy),
    dialogType: searchParams.get(GlobalSearchParam.DialogType),
  };

  function appendToSearch({
    pathname = location.pathname,
    append = {},
  }: {
    pathname?: string;
    append?: SearchParamsHash;
  }) {
    const search = searchParamsHash(append, searchParams).toString();

    return {
      pathname,
      search,
      url: `${pathname}?${search}`,
    };
  }

  const sortDescriptor: SortDescriptor | undefined = React.useMemo(() => {
    const orderBy = searchParams.get(GlobalSearchParam.OrderBy);

    if (!orderBy) {
      return undefined;
    }

    const orderByElements = orderBy.split('_');

    const directionIndex = orderByElements.findIndex((item) => item === 'ASC' || item === 'DESC');

    const column = orderByElements.slice(0, directionIndex).join('_');

    const direction = orderByElements[directionIndex];

    return {
      column: column,
      direction: direction === 'ASC' ? 'ascending' : ('descending' as SortDirection),
    };
  }, [searchParams]);

  const setSort = React.useCallback(
    (sortDescriptor: SortDescriptor) => {
      searchNavigate({
        orderBy: `${sortDescriptor.column}_${
          sortDescriptor.direction === 'ascending' ? 'ASC' : 'DESC'
        }`,
      });
    },
    [searchNavigate],
  );

  return { withSearch: appendToSearch, sortDescriptor, setSort, globalSearchParams };
}
